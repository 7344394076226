<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">车辆维护</h1>
    </div>
    <div slot="extra">
      <a-button
        style="margin-left: 16px;"
        icon="plus"
        @click="handleAdd"
        type="primary"
      >新增
      </a-button>
    </div>
    <a-card :bordered="false">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="车辆维护人">
              <a-input v-model="searchParams.userName" placeholder="请填写车辆维护人员姓名"/>
            </a-form-item>
            <a-form-item label="车辆名称">
              <a-select
                allowClear
                showSearch
                optionFilterProp="label"
                v-model="searchParams.carId"
                placeholder="请选择"
                @change="search"
              >
                <a-select-option v-for="item in carList" :key="item.id" :value="item.id" :label="item.name">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="开始日期">
              <a-date-picker format="YYYY-MM-DD"  v-model="searchParams.startTime" @change="startDate"></a-date-picker>
            </a-form-item>
            <a-form-item label="结束日期">
              <a-date-picker format="YYYY-MM-DD"  v-model="searchParams.endTime" @change="endDate">
              </a-date-picker>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
        :pagination="pagination"
        @change="tableChange"
        :rowKey="(record) => record.id"
        bordered
      >
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="maintainTime" slot-scope="text, record">
          {{moment(record.maintainTime ).format('YYYY-MM-DD')}}
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click="handleEdit(record.id)">编辑</a>
          <a-divider type="vertical"/>
          <a @click="handleDelete(record.id)">删除</a>
        </template>
      </a-table>
    </a-card>
    <!--添加Modal-->
    <a-modal title="车辆维护"
             @ok="handleAddOk"
             @cancel="closeAddModal"
             :visible="addModal"
             type="add">
      <car-config-form ref="addRef" @addSuccess="addSuccess" type="add" @addError="addError"></car-config-form>
    </a-modal>
    <!--修改Modal-->
    <a-modal
      title="修改车辆维护"
      :visible="editModal"
      @ok="handleEditOk"
      @cancel="closeEditModal"
      :confirmLoading="editLoading"
    >
      <car-config-form ref="editRef" type="edit" @editSuccess="editSuccess" @editError="editError"></car-config-form>
    </a-modal>
  </page-layout>
</template>

<script>
  import PageLayout from '@/components/page/PageLayout'
  import SERVICE_URLS from '@/api/service.url'
  import { columns } from './common/common'
  import entityCRUDWithCopy from '../../common/mixins/entityCRUDWithCopy'
  import CarConfigForm from './Form'
  import moment from 'moment'

  export default {
    name: 'carMaintainList',
    components: { CarConfigForm, PageLayout },
    mixins: [entityCRUDWithCopy],
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.car.carMaintainApi,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          userName: '',
          carId: undefined,
          startTime: '',
          endTime: ''
        },
        initColumns: columns(),
        carList: []
      }
    },
    created () {
      this.loadCarInfoList()
    },
    methods: {
      moment,
      loadCarInfoList () {
        this.$http(this, {
          url: SERVICE_URLS.car.carInfoApi.select,
          noTips: true,
          success: (data) => {
            this.carList = data.body
          }
        })
      },
      startDate (date, dateString) {
        this.searchParams.startTime = dateString
      },
      endDate (date, dateString) {
        this.searchParams.endTime = dateString
      }
    }
  }
</script>

<style scoped></style>
