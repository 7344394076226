// 车辆维护列表表头 日期，维修人，车辆、维修内容
export const columns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '车辆',
      width: 100,
      align: 'center',
      dataIndex: 'platNumber'
    },
    {
      title: '维护人',
      width: 100,
      align: 'center',
      dataIndex: 'userName'
    },
    {
      title: '部门',
      width: 200,
      align: 'center',
      dataIndex: 'orgName'
    },
    {
      title: '日期',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'maintainTime' },
      dataIndex: 'maintainTime'
    },
    {
      title: '操作',
      width: 120,
      align: 'center',
      scopedSlots: { customRender: 'action' }
    }
  ]
}

// 车辆用途类型
export const useList = function () {
  return [
    { value: 'OTHER', label: '其他' },
    { value: 'QY', label: '清运' },
    { value: 'XJ', label: '巡检' }
  ]
}

